<template>
  <div id="contents" class="storage_status">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li class="active">
            <a href="#" @click.prevent="">창고조회</a>
          </li>
        </ul>
      </div>
      <StorageStatusForm />
    </div>
  </div>
</template>

<script>
import StorageStatusForm from '@/views/forms/Storage/StorageStatusForm';

import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: { StorageStatusForm },
};
</script>

<style></style>
