<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="input_text">
          <label>창고</label>
          <my-local-selectric
            :id="'store_selectric'"
            :watch="searchStorage"
            :options="store_options"
            @changeValue="changeSelectric($event)"
          >
          </my-local-selectric>
        </div>
        <div class="input_text">
          <label>세부위치</label>
          <my-local-selectric
            :state="
              searchStorage != null && locations.length > 1 ? '' : 'disabled'
            "
            :id="'location_selectric'"
            :watch="searchLocation"
            :options="locations"
            @changeValue="changeSelectric2($event)"
          >
          </my-local-selectric>
        </div>
        <button class="btn_sub2" type="button" @click="searchLogs">조회</button>
        <a href="#" class="btn_sub1" @click.prevent="moveToStorage">
          창고관리로 이동
        </a>
      </div>
      <h6 class="select_title">창고 조회</h6>
      <h6 class="views">조회수 : {{ filterList.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>보관창고</th>
              <th>세부위치</th>
              <th>항목구분</th>
              <th>항목명</th>
              <th>규격</th>
              <th>재고량(단위)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filterList" :key="item.id">
              <td>{{ stores.find(x => x.id == item.store_id).name }}</td>
              <td>
                {{
                  item.location_id == null
                    ? ''
                    : getStoreLocation(item).find(
                        x => x.value == item.location_id,
                      ).label
                }}
              </td>
              <td>{{ item.item_type }}</td>
              <td>{{ item.item_name }}</td>
              <td>
                {{ item.standard }}
              </td>
              <td>
                {{ `${$makeComma(item.quantity)} (${item.unit})` }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import routes from '@/routes/routes';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import FETCH_MIXIN from '@/mixins/fetch';
import { getExpireDate, yyyymmdd } from '@/utils/func';

export default {
  mixins: [FETCH_MIXIN],
  components: {
    MyLocalSelectric,
  },
  data() {
    return {
      searchStorage: null,
      searchLocation: null,
      filterList: [],
    };
  },
  computed: {
    ...mapGetters({
      store_options: 'getStoresForSelectric',
      stores: 'getStores',
      materialControlList: 'getMaterialControl',
      productControlList: 'getProductControl',
      material: 'getMaterial',
      product: 'getProduct',
      material_types: 'getMaterialType',
      product_types: 'getProductType',
      unitCodes: 'getUnitCodes',
      systemCompany: 'getSystemCompany',
    }),
    locations() {
      if (this.stores.length < 1) {
        return [];
      } else {
        let filterLocation = this.stores
          .map(x => x.locations)
          .reduce((a, b) => a.concat(b))
          .filter(x => x.store_id == this.searchStorage);
        let locations = [];

        locations.push({ label: '선택', value: null });
        filterLocation.forEach(el => {
          locations.push({ label: el.name, value: el.id });
        });

        return locations;
      }
    },
    filteredAll() {
      if (
        this.materialControlList != undefined &&
        this.productControlList != undefined
      ) {
        const materialList = this.lodash
          .clonedeep(this.materialControlList)
          .map(x => ({
            ...x,
            item_name: this.material.find(y => y.id == x.material_id).name,
            item_type: this.material_types.find(
              y =>
                y.id ==
                this.material.find(z => z.id == x.material_id).resource_type_id,
            ).name,
            standard: this.material.find(y => y.id == x.material_id).standard,
            unit: this.unitCodes.find(
              y =>
                y.id ==
                this.material.find(z => z.id == x.material_id).incoming_unit_id,
            ).name,
          }));
        const productList = this.lodash
          .clonedeep(this.productControlList)
          .map(x => ({
            ...x,
            item_name: this.product.find(y => y.id == x.product_id).name,
            item_type: this.product_types.find(
              y =>
                y.id ==
                this.product.find(z => z.id == x.product_id).resource_type_id,
            ).name,
            expire_date: this.systemCompany.expire_yn
              ? `(${getExpireDate(x)}까지)`
              : '',
            standard: this.product.find(y => y.id == x.product_id).standard,
            unit: this.unitCodes.find(y => y.id == x.unit_id).name,
          }));
        let concatAll = materialList.concat(productList);
        return concatAll.filter(x => x.store_id != null);
      } else {
        return [];
      }
    },
  },
  methods: {
    moveToStorage() {
      const goingPage = routes[0].children.find(x => x.path == '/code/common');
      if (goingPage != undefined) {
        goingPage.meta.tab = 2;
      }
      this.$router.push('/code/common');
    },
    changeSelectric(type) {
      this.searchStorage = type;
      this.searchLocation = null;
    },
    changeSelectric2(type) {
      this.searchLocation = type;
    },
    getStoreLocation(stock) {
      if (stock.store_id == null) {
        return [{ label: '선택', value: null }];
      } else {
        let options_arr = [{ label: '선택', value: null }];
        const location = this.lodash.clonedeep(
          this.stores.find(x => x.id == stock.store_id).locations,
        );
        if (location.length > 0) {
          options_arr = options_arr.concat(
            location.map(x => {
              return {
                value: x.id,
                label: x.name,
              };
            }),
          );
        }
        return options_arr;
      }
    },
    getExpireDate(item) {
      const product = this.findInfoFromId(this.product, item.product_id);
      return yyyymmdd(
        new Date(
          getExpireDate(
            new Date(item.create_time),
            product.expire_year,
            product.expire_month,
            product.expire_day,
          ),
        ),
      );
    },
    async searchLogs() {
      let filteredAll = this.lodash.clonedeep(this.filteredAll);
      if (this.searchStorage != null) {
        await this.FETCH('FETCH_MATERIAL_CONTROL', '원자재 재고 관리');
        await this.FETCH('FETCH_PRODUCT_CONTROL', '제품 재고 관리');
        if (this.searchLocation == null) {
          this.filterList = filteredAll.filter(
            x => x.store_id == this.searchStorage,
          );
        } else {
          this.filterList = filteredAll.filter(
            x =>
              x.store_id == this.searchStorage &&
              x.location_id == this.searchLocation,
          );
        }
      } else {
        this.openOneButtonModal('창고 선택', '창고를 선택해 주세요.');
      }
    },
  },
  async created() {
    if (this.stores.length == 0) {
      await this.FETCH('FETCH_STORE', '창고');
    }
    if (this.unitCodes.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.material.length < 1) {
      await this.FETCH('FETCH_MATERIAL', '원자재');
    }
    if (this.product.length < 1) {
      await this.FETCH('FETCH_PRODUCT', '제품');
    }
    if (this.material_types.length < 1 || this.product_types.length < 1) {
      await this.FETCH('FETCH_RESOURCE_TYPE', '항목 타입');
    }
    await this.FETCH('FETCH_MATERIAL_CONTROL', '원자재 재고 관리');
    await this.FETCH('FETCH_PRODUCT_CONTROL', '제품 재고 관리');
  },
};
</script>

<style></style>
